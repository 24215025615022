/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import {saveAs} from 'file-saver';
import Constants from '../../constants';
import {StoreMixin} from '../../mixins';
import {Link, Navigation, State} from 'react-router';
import {OrgStore, RuleSearchStore, SessionStore} from '../../stores';
import actionCreators from '../../actions/actionCreators';
import {Select} from '../../components/FormComponents';
import {getSessionUri, getInstanceUri} from '../../lib/api';
import {ToolBar, ToolGroup} from '../../components/ToolBar';
import {
  RestApiUtils,
  ServiceUtils,
  RulesetUtils,
  EnforcementBoundariesUtils,
  ProviderConsumerUtils,
  GridDataUtils,
  RenderUtils,
} from '../../utils';
import {
  Button,
  SpinnerOverlay,
  Pagination,
  Icon,
  NotificationGroup,
  RuleGrid,
  Banner,
  Notification,
} from '../../components';
import {RuleSearchGrid, RuleSearchPicker, RuleSearchGridColumnsSelect} from '../../components/RuleSearch';
import {webStorageUtils} from '@illumio-shared/utils';
import ExplorerBoundaryPolicyUtils from '../../utils/Explorer/ExplorerBoundaryPolicyUtils';

// Commented code in 56b2314b29be89c4ad008b72718f816e7976488c
const MAX_FILTERS = 8;

const columnKeyLabels = () => ({
  ...(!__ANTMAN__ && {'rule_set.scopes': intl('Common.Scopes')}),
  'providers': intl('Common.Destinations'),
  'consumers': intl('Common.Sources'),
  'update_type': intl('Common.ProvisionStatus'),
  'enabled': intl('Common.Status'),
  'sec_connect': (
    <span>
      {!__ANTMAN__ && <Icon name="lock" size="small" />}
      {` ${intl('Common.SecureConnect')}`}
    </span>
  ),
  'machine_auth': intl('Common.MachineAuthentication'),
  'stateless': intl('Common.Stateless'),
  'ingress_services': __ANTMAN__ ? intl('Common.Service') : intl('Rulesets.Rules.ProvidingService'),
  ...(!__ANTMAN__ && {
    unscoped_consumers: !__ANTMAN__ && (
      <span>
        <Icon name="global" size="small" />
        {` ${intl('PolicyGenerator.ExtraScopeRules')}`}
      </span>
    ),
  }),
  'rule_set.name': intl('Explorer.Policy'),
  'rule_set.href': intl('RuleSearch.Access'),
  'description': intl('Common.Note'),
  'created_at': intl('RuleSearch.CreatedAtUc'),
  'updated_at': intl('RuleSearch.UpdatedAtUc'),
  'created_by_user': intl('Common.CreatedBy'),
  'updated_by_user': intl('Common.LastModifiedBy'),
});
const dateTimeValues = () => ({
  [intl('DateTimeInput.Last1Hour')]: ['h', 1],
  [intl('DateTimeInput.Last24Hours')]: ['h', 24],
  [intl('DateTimeInput.Last7days')]: ['d', 7],
  [intl('DateTimeInput.Last30days')]: ['d', 30],
});

const groupMatches = (ref, group) =>
  !group?.href?.includes('discover') && group?.href?.split('x').every(id => ref.includes(id));

const boundaryGeneratorRuleset = (ruleset, group) =>
  ruleset.external_data_set === 'illumio_boundary_ruleset' && groupMatches(ruleset.external_data_reference, group);

const segmentationTemplate = ruleset => ruleset.external_data_set === 'illumio_segmentation_templates';

const sortRulesets = (rulesets, boundaries) =>
  rulesets.sort((a, b) => {
    const bSort =
      b.scopes[0].length +
      (boundaryGeneratorRuleset(b, boundaries) ? 100 : 0) +
      50 * _.isEqual(b.scopes, ExplorerBoundaryPolicyUtils.getScopeForBoundaryRuleset(boundaries)) -
      (segmentationTemplate(b) ? 100 : 0);

    const aSort =
      a.scopes[0].length +
      (boundaryGeneratorRuleset(a, boundaries) ? 100 : 0) +
      50 * _.isEqual(a.scopes, ExplorerBoundaryPolicyUtils.getScopeForBoundaryRuleset(boundaries)) -
      (segmentationTemplate(a) ? 100 : 0);

    return bSort - aSort;
  });

function getStateFromStore() {
  return {
    rules: RuleSearchStore.getRules().map(RulesetUtils.addUsageEntities),
    allItems: RuleSearchStore.getFilters(),
    count: RuleSearchStore.getCount(),
    isBusy: [RuleSearchStore.getStatus(), OrgStore.getStatus()].includes(Constants.STATUS_BUSY),
  };
}

export default Object.assign(
  React.createClass({
    mixins: [Navigation, State, StoreMixin([RuleSearchStore, OrgStore], getStateFromStore)],

    getInitialState() {
      return {
        pageLength: 50,
        hideColumns: [
          ...(this.getPath().includes('boundaries') ? [] : ['update_type']),
          ...(this.getPath().includes('boundaries') ? ['rule_set.name'] : []),
          ...(this.getPath().includes('boundaries') ? ['rule_set.href'] : []),
          'rule_set.scopes',
          'enabled',
          'sec_connect',
          'machine_auth',
          'stateless',
          'created_at',
          'updated_at',
          'created_by_user',
          'updated_by_user',
          'unscoped_consumers',
        ],
        view: 'basic',
        search: _.isEmpty(RuleSearchStore.getFilters()) ? 'exact' : 'all', // Deep search if scope is passed from ruleset detail page
        sorting: [],
        currentPage: 1,
        invalidFilters: false,
        showColumnsTable: false,
        groupType: this.getPathname().split('/')[1],
        providerConsumerOrder: OrgStore.providerConsumerOrder(),
        isAllServices: false,
      };
    },

    handleRowClick(row) {
      this.transitionTo('rulesets.item', {
        pversion: this.getParams().pversion,
        id: row.rule_set.href.split('/').pop(),
        tab: row.unscoped_consumers ? 'extrascope' : 'intrascope',
      });
    },

    handleSort(key, direction) {
      const sorting = [{key, direction}];

      this.setState({sorting});
    },

    componentWillMount() {
      if (SessionStore.isUserWithReducedScope()) {
        this.replaceWith('landing');
      }
    },

    async componentDidMount() {
      await RestApiUtils.ipLists.getInstance('any');

      await this.handleSelectiveEnforcement();

      if (SessionStore.isUserWithReducedScope()) {
        return;
      }

      await RestApiUtils.users.getCollection();
      _.defer(() => this.loadRules(this.state.allItems));
    },

    componentWillUnmount() {
      actionCreators.setRuleSearchFilters({});
    },

    handleRefresh() {
      this.loadRules(this.state.allItems, true);
      this.setState({currentPage: 1});
    },

    handleRuleSearchFiltersChange(items, type) {
      const allItems = {...this.state.allItems, [type]: items};
      const itemsKeys = Object.keys(items);
      const itemsValues = Object.values(items);

      if (itemsKeys.length > 0 && !itemsValues[itemsValues.length - 1]) {
        // No value selected for the facet
        return;
      }

      this.setState({allItems}, this.setInvalidFilters);
    },

    handleGo() {
      const {allItems} = this.state;
      const isFiltered = !_.isEmpty(_.omitBy(allItems, _.isEmpty));

      this.loadRules(allItems);
      this.setState({currentPage: 1, isFiltered});
    },

    async handleSelectiveEnforcement() {
      const isSelectiveRoute = this.getPath().includes('boundaries');

      // Set the proper label scopes
      if (isSelectiveRoute) {
        const {pversion, id} = this.getParams();

        let details;

        // Retrieve session from Tesse React
        let enforcements = webStorageUtils.getSessionItem('selectiveInstanceLabels') || {};

        // This block of code is used when logging out of Selective Enforcement Rule tab then re-login.
        // Need to retrieve selective enforcement rules. During a logout, all webStorageUtils is cleared.
        if (_.isEmpty(enforcements)) {
          try {
            details = await EnforcementBoundariesUtils.handleEnforcementBoundariesLists(pversion, id);

            const selectivePolicyVersions = EnforcementBoundariesUtils.getSelectivePolicyVersions(details, pversion);

            enforcements = EnforcementBoundariesUtils.getSegmentationLabels(selectivePolicyVersions.versions, id);
          } catch {
            this.transitionTo('boundaries.list');
          }
        }

        const enforcementLabels = enforcements?.scopeLabels[pversion] ?? {};

        const providerLabels = EnforcementBoundariesUtils.getScopeEnforcementLabels(enforcementLabels.providers);
        const providerIngress = EnforcementBoundariesUtils.getIngressServices(enforcementLabels.ingress_services);
        const consumerLabels = EnforcementBoundariesUtils.getScopeEnforcementLabels(enforcementLabels.consumers);

        // Rules has providerIngress (services) with providers
        const allItems = {
          providers: {
            ...(providerLabels.allWorkloads.length && {[intl('Workloads.All')]: providerLabels.allWorkloads}),
            ...(providerLabels.ipLists.length && {[intl('Common.IPLists')]: providerLabels.ipLists}),
            ...(providerLabels.labelGroups.length && {[intl('Labels.Groups')]: providerLabels.labelGroups}),
            ...(providerLabels.labels.length && {[intl('Common.Labels')]: providerLabels.labels}),
          },
          consumers: {
            ...(consumerLabels.labelGroups.length && {[intl('Labels.Groups')]: consumerLabels.labelGroups}),
            ...(consumerLabels.labels.length && {[intl('Common.Labels')]: consumerLabels.labels}),
            ...(consumerLabels.allWorkloads.length && {[intl('Workloads.All')]: consumerLabels.allWorkloads}),
            ...(consumerLabels.ipLists.length && {[intl('Common.IPLists')]: consumerLabels.ipLists}),
          },
        };

        // Set the ingress services for providers
        for (const x in providerIngress) {
          if (providerIngress[x].length) {
            allItems.providers[x] = providerIngress[x];
          }
        }

        const enforcementExternals = ExplorerBoundaryPolicyUtils.getExternalDataForBoundaryRuleset(id);

        /** Retrieve the Rule Set that has an enforcement boundary with external_data_set: 'illumio_boundary_ruleset'
         * to indicate this is an Enforcement Boundary rule
         */
        const enforcementRules = await EnforcementBoundariesUtils.getEnforcementRulesets({
          enforcementExternals,
        });

        const enforcementRuleSet = enforcementRules?.body?.[0] ?? {};

        // Set to state.view = 'advanced' use in loadRules() to parse the correct data
        // Note: 'advance' view has both consumers and providers.
        // Enforcement Boundary uses 'providers'
        // state.isFiltered is used to get the matched count
        this.setState(() => ({
          view: 'advanced',
          isFiltered: true,
          isAllServices: providerIngress.isAllServices,
          boundariesInfo: {enforcementRuleSet, enforcementLabels},
        }));

        // Save initial enforcement boundary labels to disallow users from removing from list
        this.enforcementBoundaryLabels = {
          providers: [
            ...providerLabels.labelGroups,
            ...providerLabels.labels,
            ...providerLabels.allWorkloads,
            ...providerLabels.ipLists,
            ...providerIngress[intl('Port.Port')],
            ...providerIngress[intl('Port.PortRange')],
            ...providerIngress[intl('Common.Protocol')],
            ...providerIngress[intl('RuleSearch.WindowsProcessName')],
            ...providerIngress[intl('RuleSearch.WindowsServiceName')],
          ],
          consumers: [
            ...consumerLabels.labelGroups,
            ...consumerLabels.labels,
            ...consumerLabels.allWorkloads,
            ...consumerLabels.ipLists,
          ],
        };

        // By calling setRuleSearchFilters() two things happen:
        // 1) Set sessionStorage which is retrieved from RuleSearchStore.getFilters() thus during refresh item is retrieved
        // 2) Set the RuleSearchStore's filters object
        actionCreators.setRuleSearchFilters(allItems);
      }
    },

    getStrippedArray(values, title, stringOnly) {
      // Return Objects with only 'href' key
      // stringOnly means return the 'href' key as is
      // and not in a object with 'href' key
      return values.map(({href}) => (stringOnly ? href : {[title]: {href}}));
    },

    getRuleActors(items = {}) {
      // This Function takes the ObjectSelector filters and returns the
      // Rule Actors filters in it
      const actors = [];
      const ipAddresses = [];
      const labels = [];

      Object.keys(items).forEach(key => {
        if (!items[key]) {
          return;
        }

        switch (key) {
          case intl('Workloads.All'):
            actors.push({actors: 'ams'});
            break;
          case intl('Common.Labels'):
            this.getStrippedArray(items[key], null, true).forEach(href => {
              labels.push(href);
            });
            break;
          case intl('Common.IPLists'):
            actors.push(...this.getStrippedArray(items[key], 'ip_list'));
            break;
          case intl('Labels.Groups'):
            actors.push(...this.getStrippedArray(items[key], 'label_group'));
            break;
          case intl('Common.VirtualServers'):
            actors.push(...this.getStrippedArray(items[key], 'virtual_server'));
            break;
          case intl('Common.VirtualServices'):
            actors.push(...this.getStrippedArray(items[key], 'virtual_service'));
            break;
          case intl('Common.Workloads'):
            actors.push(...this.getStrippedArray(items[key], 'workload'));
            break;
          case intl('Common.IPAddress'):
            items[key].forEach(ipAddress => {
              ipAddresses.push(ipAddress.value.trim());
            });
            break;
        }
      });

      if (ipAddresses.length) {
        actors.push(...ipAddresses.map(ipAddress => ({ip_address: ipAddress})));
      }

      if (labels.length) {
        actors.push(...labels.map(href => ({label: {href}})));
      }

      return actors;
    },

    getRuleQuery(items = {}) {
      // This Function takes the ObjectSelector filters and returns the
      // Rule Attribute filters in it
      const query = {};
      const {groupType} = this.state;

      let isAllServices = false;

      // All the Boolean Rule Attribute filters
      const booleanMap = {
        [intl('Common.Status')]: 'enabled',
        [intl('Common.MachineAuthentication')]: 'machine_auth',
        [intl('Common.Stateless')]: 'stateless',
        [intl('Common.SecureConnect')]: 'sec_connect',
      };
      const updateTypeMap = {
        [intl('Provision.PendingAddition')]: 'create',
        [intl('Provision.PendingModification')]: 'update',
        [intl('Provision.PendingDeletion')]: 'delete',
      };

      Object.keys(items).forEach(key => {
        if (!items[key]) {
          return;
        }

        switch (key) {
          case intl('Common.ProvisionStatus'):
            query.update_type = updateTypeMap[items[key][0]];
            break;
          case intl('Common.Status'):
            query[booleanMap[key]] = items[key][0] === intl('Common.Enabled');
            break;
          case intl('Common.MachineAuthentication'):
          case intl('Common.Stateless'):
          case intl('Common.SecureConnect'):
            query[booleanMap[key]] = items[key][0] === intl('Common.On');
            break;
          case intl('PolicyGenerator.ExtraScopeRules'):
            query.unscoped_consumers = true;
            break;
          case intl('Common.AllServices'):
            if (groupType === 'boundaries') {
              isAllServices = true;
            }

            query.ingress_services ||= [];
            query.ingress_services.push({
              href: getSessionUri(getInstanceUri('services'), {
                service_id: 'all_services',
                pversion: this.getParams().pversion,
              }),
            });
            break;
          case intl('Common.CreatedBy'):
            query.created_by = {
              user: {username: items[key][0].username},
            };
            break;
          case intl('Common.UpdatedBy'):
            query.updated_by = {
              user: {username: items[key][0].username},
            };
            break;
          case intl('RuleSearch.CreatedAtUc'):
          case intl('RuleSearch.UpdatedAtUc'):
          case intl('RuleSearch.CustomCreatedAtRange'):
          case intl('RuleSearch.CustomUpdatedAtRange'):
            const timestamps = {};
            let apiKey;

            if ([intl('RuleSearch.CreatedAtUc'), intl('RuleSearch.CustomCreatedAtRange')].includes(key)) {
              apiKey = 'created_at';
            } else {
              apiKey = 'updated_at';
            }

            if ([intl('RuleSearch.CreatedAtUc'), intl('RuleSearch.UpdatedAtUc')].includes(key)) {
              for (const [dtKey, dtValue] of Object.entries(dateTimeValues())) {
                if (items[key][0].includes(dtKey.toLocaleLowerCase())) {
                  timestamps.gte = intl.utils.subtractTime(new Date(), ...dtValue);
                }
              }
            }

            query[apiKey] = timestamps;
            break;
          case intl('Common.UserGroups'):
            query.consuming_security_principals = items[key].map(({href}) => ({href}));
            break;
          case intl('Common.Services'):
            query.ingress_services ||= [];

            items[key].forEach(service => {
              if (service && service.href) {
                query.ingress_services.push({href: service.href});
              }
            });

            break;
          case intl('Common.UsesVirtualServices'):
            query.service = null;
            break;
          case intl('Common.UsesVirtualServicesWorkloads'):
            query.service = null;
            break;
          case intl('Port.Port'):
            query.ingress_services ||= [];

            items[key].forEach(item => {
              const [port, proto] = item.value.split(' ');

              query.ingress_services.push({
                port: Number(port),
                proto: ServiceUtils.reverseLookupProtocol(proto),
              });
            });
            break;
          case intl('Port.PortRange'):
            query.ingress_services ||= [];

            items[key].forEach(item => {
              const [port, toPort, proto] = item.value.split(/-| /);

              query.ingress_services.push({
                port: Number(port),
                to_port: Number(toPort),
                proto: ServiceUtils.reverseLookupProtocol(proto),
              });
            });
            break;
          case intl('RuleSearch.WindowsProcessName'):
          case intl('RuleSearch.WindowsServiceName'):
            let windowsServiceKey;

            if (key === intl('RuleSearch.WindowsProcessName')) {
              windowsServiceKey = 'process_name';
            } else {
              windowsServiceKey = 'service_name';
            }

            query.ingress_services ||= [];

            items[key].forEach(item => {
              query.ingress_services.push({
                [windowsServiceKey]: item.value,
              });
            });

            break;
          case intl('Common.Protocol'):
            query.ingress_services ||= [];

            items[key].forEach(proto => {
              query.ingress_services.push({
                proto: ServiceUtils.reverseLookupProtocol(proto.trim()),
              });
            });

            break;
          case intl('RuleSearch.RulesetName'):
            query.rule_set = {name: items[key][0]};
            break;
          case intl('Common.Note'):
            query.description = items[key][0].value;
            break;
        }
      });

      if (this.state.search === 'all' || this.state.groupType === 'boundaries') {
        query.resolve_actors = true;

        if (!isAllServices) {
          query.exact_service_match = false;
        }
      }

      /** When All Services exist don't pass in ingress_services primarily used for rules **/
      if (isAllServices) {
        delete query.ingress_services;
      }

      return query;
    },

    getResolveLabelsAs(items = {}, type) {
      const resolveLabelsAs = {};

      Object.keys(items).forEach(key => {
        if (!items[key]) {
          return;
        }

        switch (key) {
          case `${intl('Common.Destinations')} ${intl('Common.UsesVirtualServicesWorkloads')}`:
            resolveLabelsAs.providers = ['virtual_services', 'workloads'];
            break;
          case `${intl('Common.Destinations')} ${intl('Common.UsesVirtualServices')}`:
            resolveLabelsAs.providers = ['virtual_services'];
            break;
          case `${intl('Common.Sources')} ${intl('Common.UsesVirtualServicesWorkloads')}`:
            resolveLabelsAs.consumers = ['virtual_services', 'workloads'];
            break;
          case `${intl('Common.Sources')} ${intl('Common.UsesVirtualServices')}`:
            resolveLabelsAs.providers = ['virtual_services'];
            break;
          case intl('Common.UsesVirtualServicesWorkloads'):
            resolveLabelsAs[type] = ['virtual_services', 'workloads'];
            break;
          case intl('Common.UsesVirtualServices'):
            resolveLabelsAs[type] = ['virtual_services'];
            break;
        }
      });

      return resolveLabelsAs;
    },

    loadRules(allItems, noCache) {
      this.rulesVersion = this.getParams().pversion;

      let query;

      if (this.state.view === 'basic') {
        const actors = this.getRuleActors(allItems.providers_or_consumers);

        query = this.getRuleQuery(allItems.providers_or_consumers);

        if (actors.length) {
          query.providers_or_consumers = actors;
        }

        const resolveLabelsAs = this.getResolveLabelsAs(allItems.providers_or_consumers);

        if (!_.isEmpty(resolveLabelsAs)) {
          query.resolve_labels_as = resolveLabelsAs;
        }
      } else {
        const providers = this.getRuleActors(allItems.providers);
        const consumers = this.getRuleActors(allItems.consumers);

        query = this.getRuleQuery({
          ...allItems.providers,
          ...allItems.consumers,
        });

        if (providers.length) {
          query.providers = providers;
        }

        if (consumers.length) {
          query.consumers = consumers;
        }

        const resolveLabelsAs = {
          ...this.getResolveLabelsAs(allItems.providers, 'providers'),
          ...this.getResolveLabelsAs(allItems.consumers, 'consumers'),
        };

        if (!_.isEmpty(resolveLabelsAs)) {
          query.resolve_labels_as = resolveLabelsAs;
        }
      }

      if (query.ingress_services && !query.ingress_services.length) {
        delete query.ingress_services;
      }

      if (!_.isEmpty(allItems)) {
        actionCreators.setRuleSearchFilters(allItems);
      }

      const version = this.state.groupType === 'boundaries' ? 'draft' : this.getParams().pversion;

      RestApiUtils.ruleSearch.getCollection(query, version, noCache);
    },

    handleVersionSelect(value) {
      this.transitionTo('rulesets.ruleSearch', {}, {ruleSearchGrid: {filter: {pversion: value}}});
    },

    componentDidUpdate() {
      const curPath = this.getPathname().split('/')[1];

      /**
       * Transition from 'boundaries/rulesearch/146/draft/' to 'rulesearch/draft'
       */
      if (curPath !== this.state.groupType) {
        this.setState({groupType: curPath, allItems: []});
        this.loadRules([]);

        return;
      }

      if (
        this.getParams().pversion !== this.rulesVersion &&
        !_.isEqual(this.state.allItems, RuleSearchStore.getFilters())
      ) {
        this.loadRules(this.state.allItems);

        const {pageLength, hideColumns, view, sorting, allItems} = this.state;

        this.setState({
          ...this.getInitialState(),
          pageLength,
          hideColumns,
          view,
          sorting,
          allItems,
        });
      }
    },

    handleViewSelect(value) {
      this.setState({view: value, currentPage: 1}, () => {
        this.loadRules(this.state.allItems);
      });
    },

    handleSearchSelect(value) {
      this.setState({search: value, currentPage: 1}, () => {
        this.loadRules(this.state.allItems);
      });
    },

    handleColumnsChange(hideColumns) {
      this.setState({hideColumns});
    },

    handleResetFilters() {
      this.setState(this.getInitialState(), this.handleRefresh);
    },

    getRuleSearchPicker() {
      let types;

      const isSelectiveRulesList = this.state.groupType === 'boundaries';

      if (this.state.view === 'basic') {
        types = ['providers_or_consumers'];
      } else {
        types = ProviderConsumerUtils.setProviderConsumerColumnOrder(
          'providers',
          null,
          'consumers',
          this.state.providerConsumerOrder,
        );
      }

      /** Logic to skip provider or consumer option, need to return null */
      return types.map(type => {
        let disableSpecificItems = [];

        if (isSelectiveRulesList) {
          if (type === 'providers') {
            // Don't allow specific items to be removed
            disableSpecificItems = this.enforcementBoundaryLabels?.providers;
          } else if (type === 'consumers') {
            disableSpecificItems = this.enforcementBoundaryLabels?.consumers;
          }
        }

        return (
          <RuleSearchPicker
            type={type}
            disableSpecificItems={disableSpecificItems}
            pversion={this.getParams().pversion}
            selected={this.state.allItems[type]}
            onChange={this.handleRuleSearchFiltersChange}
          />
        );
      });
    },

    getFiltersCount(object) {
      let count = 0;

      for (const [, value] of Object.entries(object || {})) {
        if (Array.isArray(value)) {
          count += value.length;
        } else if (value !== undefined && value !== null) {
          count += 1;
        }
      }

      return count;
    },

    setInvalidFilters() {
      // All three entity type filters => "providers_or_consumers",
      // "providers" and "consumers" only support maximum eight entities each.
      const {view, allItems} = this.state;
      let invalidFilters = false;

      if (view === 'basic') {
        invalidFilters = this.getFiltersCount(allItems.providers_or_consumers) > MAX_FILTERS;
      } else {
        invalidFilters =
          this.getFiltersCount(allItems.providers) > MAX_FILTERS ||
          this.getFiltersCount(allItems.consumers) > MAX_FILTERS;
      }

      this.setState({invalidFilters});
    },

    async handleDownload() {
      const oldHideColumns = [...this.state.hideColumns];

      await new Promise(resolve => this.setState({pageLength: 500, hideColumns: []}, resolve));

      const table = document.querySelector('.Grid');
      const allRows = table.querySelectorAll('tr');
      const csvString = [];

      allRows.forEach(row => {
        const allColumns = [...row.querySelectorAll('th'), ...row.querySelectorAll('td')];
        const allColumnsStrings = [];

        allColumns.forEach(column => {
          // replace(/[^\x00-\x7F]/g, '')       => Remove non ASCII
          // replace(/^\s*$(?:\r\n?|\n)/gm, '') => Remove Blank Lines
          // replace(/\n/g, ',')                => Replace empty lines with comma
          /*eslint-disable no-control-regex*/
          const string = `"${column.textContent
            // Replace hair space (set in GridDataUtils) with ', '. Eagerly swallow any following specials with it
            .replaceAll(/\u200A[^\u0000-\u007F]*/g, ', ')
            // Remove '+ N more' wrapped with thin space (set in GridDataUtils)
            .replaceAll(/\u2009.+\u2009/g, '')
            // Replace zero-width space (set in GridDataUtils) after the service name with ':'
            .replaceAll('﻿', ':')
            // Replace specials (like icons) with spaces
            .replaceAll(/[^\u0000-\u007F]/g, ' ')
            .replaceAll(/^\s*$(?:\r\n?|\n)/gm, '')
            .trim()
            .replaceAll('\n', ', ')}"`;
          /*eslint-enable no-control-regex*/

          allColumnsStrings.push(string);
        });

        csvString.push(allColumnsStrings.join(','));
      });

      const blob = new Blob([csvString.join('\n')], {type: 'text/csv;charset=utf-8'});

      saveAs(blob, `rulesearch_${Date.now()}.csv`);

      this.setState({pageLength: 50, hideColumns: oldHideColumns});
    },

    handlePageChange(page) {
      this.setState({currentPage: page});
    },

    renderEnforcementBoundaries({rulesets = [], providerConsumerOrder = '', isEnforcement = false}) {
      const grids = [];
      let ruleCount = 0;

      if (rulesets && rulesets.length) {
        _.forEach(rulesets, ruleset => {
          // let policyGenerator = null;

          if (ruleset && ruleset.rules && ruleset.rules.length) {
            const status = ruleset.enabled || !ruleset.hasOwnProperty('enabled') ? '' : ` [${intl('Common.Disabled')}]`;
            const routeParams = ruleset.href
              ? {
                  id: GridDataUtils.getIdFromHref(ruleset.href),
                  pversion: 'draft',
                  tab: ruleset.rules.every(row => row.unscoped_consumers) ? 'extrascope' : 'intrascope',
                }
              : null;

            const scopes = RenderUtils.getScope(ruleset);

            ruleCount += ruleset.rules.length;

            const rulesetName = RenderUtils.truncateAppGroupName(ruleset.name, 120, [60, 30, 30]) + status;

            grids.push(
              <div className="GroupRules-Ruleset-Rules" data-tid="grouprules-ruleset-rules">
                <div className="GroupRules-Ruleset">
                  {ruleset.href ? (
                    <Link className="Toolbar-link" to="rulesets.item" params={routeParams} data-tid="ruleset-link">
                      {rulesetName}
                    </Link>
                  ) : (
                    rulesetName
                  )}
                  <div className="GroupRules-Ruleset-Scope">
                    <div>{scopes}</div>
                    {isEnforcement ? (
                      <div className="GroupRules-Ruleset-EnforcementTitle">
                        {intl('Rulesets.EnforcementBoundaryRuleset')}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <RuleGrid
                  tid="grid-rules"
                  rules={ruleset.rules}
                  version="draft"
                  rulesetEnabled={ruleset.enabled}
                  extraScopeEnabled
                  providerConsumerOrder={providerConsumerOrder}
                  disableEmptyRules={isEnforcement}
                />
              </div>,
            );
          }
        });
      } else if (!isEnforcement) {
        grids.push(<Banner type="notice" header={intl('Common.NoRules')} />);
      }

      return {
        grids,
        ruleCount,
      };
    },

    handleOnButtonClick() {
      const {
        boundariesInfo: {enforcementRuleSet},
      } = this.state;

      /** Set 'GeneralSelection' is used for 'provisioning' when the Provision button is clicked */
      webStorageUtils.setSessionItem('GeneralSelection', [
        {
          key: 'provision',
          value: {operation: 'commit', change_subset: {rule_sets: [{href: enforcementRuleSet.href}]}},
        },
      ]);

      this.transitionTo('provision');
    },

    render() {
      const {
        rules,
        isBusy,
        showColumnsTable,
        hideColumns,
        view,
        currentPage,
        count,
        isFiltered,
        sorting,
        search,
        invalidFilters,
        groupType,
        boundariesInfo,
      } = this.state;
      const versionOptions = [
        {value: 'draft', label: intl('RuleSearch.DraftRules')},
        {value: 'active', label: intl('RuleSearch.ActiveRules')},
      ];
      const viewOptions = [
        {
          value: 'basic',
          label: intl('RuleSearch.Basic'),
          sublabel: intl('RuleSearch.BasicDescription'),
        },
        {
          value: 'advanced',
          label: intl('RuleSearch.Advanced'),
          sublabel: intl('RuleSearch.AdvancedDescription'),
        },
      ];
      const searchOptions = [
        {
          value: 'exact',
          label: intl('RuleSearch.ExactResults'),
          sublabel: intl('RuleSearch.ExactResultsDescription'),
        },
        {
          value: 'all',
          label: intl('RuleSearch.AllResults'),
          sublabel: intl('RuleSearch.AllResultsDescription'),
        },
      ];

      if (!['draft', 'active'].includes(this.getParams().pversion)) {
        versionOptions.push({value: this.getParams().pversion, label: this.getParams().pversion});
      }

      const isSelectiveRules = groupType === 'boundaries';
      const routeParams = this.getParams();

      let grids = null;

      const classNames = cx('RuleSearch', {
        'RuleSearch--Advanced': view === 'advanced',
      });

      if (isSelectiveRules) {
        const {id = ''} = webStorageUtils.getSessionItem('selectiveInstanceLabels') || {};

        if (id) {
          routeParams.id = id;
        }
      }

      let ruleRows = rules;

      // When rules exist, and isBusy fetching API, don't show any data especially for selective enforcement when coming from Explorer
      if (isSelectiveRules && rules?.length && isBusy) {
        ruleRows = [];
      }

      let ruleCount = rules.length;

      if (boundariesInfo && isSelectiveRules) {
        const enforcementRuleHref = boundariesInfo.enforcementRuleSet.href;

        const rulesetInfo = rules.reduce(
          (result, rule) => {
            if (result.boundaries[rule.rule_set.href] || result.rulesets[rule.rule_set.href]) {
              if (enforcementRuleHref === rule.rule_set.href) {
                result.boundaries[rule.rule_set.href].rules.push(rule);
              } else {
                result.rulesets[rule.rule_set.href].rules.push(rule);
              }

              return result;
            }

            if (enforcementRuleHref === rule.rule_set.href) {
              result.boundaries[rule.rule_set.href] = {
                ...rule.rule_set,
                rules: [rule],
              };
            } else {
              result.rulesets[rule.rule_set.href] = {
                ...rule.rule_set,
                rules: [rule],
              };
            }

            return result;
          },
          {boundaries: {}, rulesets: {}},
        );

        const boundariesRulesets = Object.values(rulesetInfo.boundaries);
        const rulesetsData = Object.values(rulesetInfo.rulesets);

        rulesetInfo.boundaries = sortRulesets(boundariesRulesets, this.state.boundariesInfo.enforcementRuleSet);
        rulesetInfo.rulesets = sortRulesets(rulesetsData, this.state.boundariesInfo.enforcementRuleSet);

        const enforcementRuleSetGrid = this.renderEnforcementBoundaries({
          rulesets: boundariesRulesets,
          providerConsumerOrder: this.state.providerConsumerOrder,
          isEnforcement: true,
        });

        const ruleSetGrid = this.renderEnforcementBoundaries({
          rulesets: rulesetsData,
          providerConsumerOrder: this.state.providerConsumerOrder,
          isEnforcement: boundariesRulesets.length && !rulesetsData.length,
        });

        ruleCount = enforcementRuleSetGrid.ruleCount + ruleSetGrid.ruleCount;

        let notifications = null;

        /** When 'boundariesInfo.enforcementRuleSet?.update_type === null | undefined' then doesn't need to be provision */
        if (enforcementRuleSetGrid.grids?.length) {
          notifications = (
            <Notification
              type="instruction"
              button={
                <Button
                  disabled={
                    !boundariesInfo.enforcementRuleSet?.caps?.includes('provision') ||
                    !boundariesInfo.enforcementRuleSet?.update_type
                  }
                  onClick={this.handleOnButtonClick}
                  text={intl('Common.Provision')}
                  tid="provision"
                />
              }
              message={intl('EnforcementBoundaries.RulesProvision', {count: enforcementRuleSetGrid.ruleCount})}
            />
          );
        }

        grids = [...enforcementRuleSetGrid.grids, ...ruleSetGrid.grids];

        if (notifications) {
          grids.unshift(notifications);
        }
      } else {
        grids = (
          <RuleSearchGrid
            rules={ruleRows}
            onRowClick={this.handleRowClick}
            pversion={this.getParams().pversion}
            onSort={this.handleSort}
            sorting={sorting}
            currentPage={currentPage}
            showColumnsTable={showColumnsTable}
            hideColumns={hideColumns}
            pageLength={this.state.pageLength}
            providerConsumerOrder={this.state.providerConsumerOrder}
          />
        );
      }

      return (
        <div className={classNames}>
          {isBusy ? <SpinnerOverlay /> : null}
          {invalidFilters && !isSelectiveRules ? (
            <div className="RuleSearch-MaxFiltersError">
              <ToolBar hasObjectSelector>
                <ToolGroup hasObjectSelector>
                  <NotificationGroup
                    notifications={[
                      {
                        type: 'error',
                        title: intl('RuleSearch.MaxEntitiesError'),
                      },
                    ]}
                  />
                </ToolGroup>
              </ToolBar>
            </div>
          ) : null}
          {isSelectiveRules ? null : (
            <ToolBar spaceBetween>
              <ToolGroup>
                <div className="RuleSearch-ViewOptions">
                  <Select
                    options={versionOptions}
                    onChange={this.handleVersionSelect}
                    value={this.getParams().pversion}
                    disabled={invalidFilters}
                    tid="version"
                  />
                </div>
                <div className="RuleSearch-SearchOptions">
                  <Select
                    options={viewOptions}
                    onChange={this.handleViewSelect}
                    value={view}
                    disabled={invalidFilters}
                    tid="view"
                  />
                </div>
                <div className="RuleSearch-SearchOptions">
                  <Select
                    options={searchOptions}
                    onChange={this.handleSearchSelect}
                    value={search}
                    disabled={invalidFilters}
                    tid="search"
                  />
                </div>
              </ToolGroup>
              <div className="RuleSearchToolGroupPaginationContainer">
                <ToolGroup tid="pagination">
                  <Pagination
                    page={currentPage}
                    totalRows={rules.length}
                    count={count}
                    isFiltered={isFiltered}
                    pageLength={this.state.pageLength}
                    onPageChange={this.handlePageChange}
                  />
                  <Button
                    icon="refresh"
                    content="icon-only"
                    type="secondary"
                    onClick={this.handleRefresh}
                    tid="refresh"
                    disabled={invalidFilters}
                  />
                </ToolGroup>
              </div>
            </ToolBar>
          )}
          {isSelectiveRules ? null : (
            <ToolBar>
              <div className="RuleSearch--Actions">
                <RuleSearchGridColumnsSelect
                  hideColumns={hideColumns}
                  onChange={this.handleColumnsChange}
                  columnKeyLabels={columnKeyLabels()}
                />
                <div>
                  <Button
                    icon="download"
                    text={intl('Common.Download')}
                    onClick={this.handleDownload}
                    disabled={!rules.length}
                  />
                </div>
                <div>
                  <Button icon="revert" text={intl('RuleSearch.ResetFilters')} onClick={this.handleResetFilters} />
                </div>
              </div>
            </ToolBar>
          )}
          <ToolBar hasObjectSelector>
            <ToolGroup tid="rulesearch-picker" hasObjectSelector>
              {this.getRuleSearchPicker()}
              <Button
                text={intl('Common.Go')}
                onClick={this.handleGo}
                type="primary"
                tid="go"
                disabled={invalidFilters}
              />
            </ToolGroup>
          </ToolBar>
          {grids}
          <ToolBar justify="right">
            <ToolGroup tid="pagination">
              <Pagination
                page={currentPage}
                totalRows={ruleCount}
                count={count}
                isFiltered={isFiltered}
                pageLength={isSelectiveRules ? 1_000_000 : this.state.pageLength}
                onPageChange={this.handlePageChange}
              />
              <Button icon="refresh" content="icon-only" type="secondary" onClick={this.handleRefresh} tid="refresh" />
            </ToolGroup>
          </ToolBar>
        </div>
      );
    },
  }),
  {
    viewName: () => intl('Common.RuleSearch'),
    params: {pversion: 'draft'},
    isAvailable: () => !SessionStore.isUserWithReducedScope(),
  },
);
