/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import actionCreators from '../../actions/actionCreators';
import {TrafficStore, RulesetStore, SessionStore, OrgStore} from '../../stores';
import {RuleNote} from '../../components/RuleWriting';
import {RouterMixin, PolicyGeneratorMixin, StoreMixin, UserMixin} from '../../mixins';
import {
  PolicyGeneratorUtils,
  GridDataUtils,
  RulesetUtils,
  PortUtils,
  ServiceUtils,
  ProviderConsumerUtils,
} from '../../utils';
import {
  ProgressBar,
  Button,
  Banner,
  Grid,
  Label,
  Icon,
  ConfirmationDialog,
  AlertDialog,
  Checkbox,
  RadioGroup,
  Notification,
} from '../../components';
import PolicyGeneratorPortProtocolDialog from '../../modals/PolicyGeneratorPortProtocolDialog';
import {SelectService} from '../../components/CommandPanel/AddRule';

function getStateFromStores() {
  const traffic = TrafficStore.getAllRoleTraffics();
  const state = this.getPolicyGeneratorValues();
  const ipListConfig = state.ipListConfig || {};
  const {appGroup, selectedIpLists, type, ruleset, ipListExclusions, optimizeLevel, mergeType, serviceType} = state;
  const rulesetId = ruleset && ruleset.href.split('/').pop();
  let newState = {};

  if (state && traffic.length) {
    const roles = this.getAppGroupRoles(TrafficStore.getAllRoleNodes(), appGroup.href).map(role => role.href);

    newState = PolicyGeneratorUtils.parseIpListConfigTraffic(
      traffic,
      appGroup,
      selectedIpLists,
      rulesetId,
      ipListConfig,
      roles,
      optimizeLevel,
    );

    if (newState.ipListTraffic) {
      const {rules, removeRules, services, ipLists, numRules} = this.getRules(
        type,
        ipListConfig,
        ipListExclusions,
        selectedIpLists,
        ruleset,
        newState,
        mergeType,
        serviceType,
      );

      newState = {...newState, rules, removeRules, numRules, services, ipLists};
    }

    newState.roleVulnerabilities = PolicyGeneratorUtils.getRoleVulnerabilities(
      roles,
      this.state ? this.state.expandedRoles : [],
    );
  }

  const id = this.getParams() && this.getParams().id;

  if (id && (!state.appGroup || id !== state.appGroup.href) && TrafficStore.getNodeForPolicyGenerator(id)) {
    this.setAppGroup(TrafficStore.getNodeForPolicyGenerator(id));
  }

  return _.cloneDeep(newState);
}

export default React.createClass({
  mixins: [RouterMixin, PolicyGeneratorMixin, UserMixin, StoreMixin([TrafficStore, RulesetStore], getStateFromStores)],

  getInitialState() {
    return {
      showNote: null,
      showNoteTop: false,
      expandedRoles: [],
      mergeType: 'services',
      serviceType: 'port',
      scopedUser: !SessionStore.isGlobalEditEnabled(),
      providerConsumerOrder: OrgStore.providerConsumerOrder(),
    };
  },

  componentDidMount() {
    if (!this.state.appGroup) {
      this.transitionTo('policygenerator');

      return;
    }

    this.getData(this.state, 'ipList', true);
    this.useAlwaysServices = [];
  },

  async componentWillReceiveProps() {
    const id = this.getParams() && this.getParams().id;
    const appGroup = TrafficStore.getNodeForPolicyGenerator(id);

    if (id && (!this.state.appGroup || id !== this.state.appGroup.href) && appGroup) {
      this.setAppGroup(appGroup);

      const ruleset = await this.getPolicyGeneratorRuleset(appGroup);

      this.setState({ruleset, showProgress: true, appGroup}, () => {
        //reload the data for the new app group
        this.getData(this.state, 'ipList');
      });
    }
  },

  getRules(
    type,
    ipListConfig,
    ipListExclusions,
    selectedIpLists,
    ruleset,
    state,
    mergeType = 'services',
    serviceType = 'port',
    broadServiceMatch,
  ) {
    const {ipListTraffic, vulnerabilities, optimizeLevel} = state;
    const rulesetId = type === 'replace' && ruleset && ruleset.href.split('/').pop();
    let rules;

    if (ipListConfig.level === 'auto') {
      rules = PolicyGeneratorUtils.getOptimizedRules(
        ipListTraffic,
        'role',
        rulesetId,
        'ipList',
        ipListExclusions,
        vulnerabilities,
        optimizeLevel,
        serviceType,
        broadServiceMatch,
      );
    } else if (ipListConfig.type === 'tiertotier') {
      rules = PolicyGeneratorUtils.getAllServicesRules(
        ipListTraffic,
        ipListConfig.level,
        rulesetId,
        false,
        ipListExclusions,
      );
    } else {
      rules = PolicyGeneratorUtils.getMicroSegmentedRules(
        ipListTraffic,
        ipListConfig.level,
        rulesetId,
        false,
        ipListExclusions,
        null,
        serviceType,
        broadServiceMatch,
      );
    }

    rules =
      PolicyGeneratorUtils.getMergedRules(rules, ipListConfig.type === 'tiertotier' ? 'consumers' : mergeType) || [];

    const ruleData = this.getRulesToRemove(type, selectedIpLists, rules, ruleset);
    const services = PolicyGeneratorUtils.getServices({}, ruleData.rules);
    const ipLists = PolicyGeneratorUtils.getIpLists(ruleData.rules);

    return {...ruleData, services, ipLists};
  },

  handleCancel() {
    this.transitionTo('policygenerator');
  },

  getRulesToRemove(type, selectedIpLists, rules, ruleset) {
    const removeRules =
      type === 'replace' && ruleset && ruleset.href && ruleset.rules
        ? ruleset.rules.filter(rule => {
            const enabled = rule.enabled && rule.update_type !== 'delete';
            const inSelectedIpLists = selectedIpLists.some(
              ipList =>
                (ipList.split('x')[1] === intl('Common.Outbound') &&
                  rule.providers.some(
                    provider => provider.ip_list && provider.ip_list.href === ipList.split('x')[0],
                  )) ||
                (ipList.split('x')[1] === intl('Common.Inbound') &&
                  rule.consumers.some(consumer => consumer.ip_list && consumer.ip_list.href === ipList.split('x')[0])),
            );

            return enabled && inSelectedIpLists;
          })
        : [];

    return PolicyGeneratorUtils.RemoveDuplicateRules(rules, removeRules || []);
  },

  handleResetRules(mergeType, serviceType, broadServiceMatch) {
    const {type, ipListConfig, ipListExclusions, selectedIpLists, ruleset} = this.state;

    this.setState(
      this.getRules(
        type,
        ipListConfig,
        ipListExclusions,
        selectedIpLists,
        ruleset,
        this.state,
        mergeType,
        serviceType,
        broadServiceMatch,
      ),
    );
  },

  handleSave() {
    const ruleCount = this.state.rules ? this.state.rules.length : 0;
    const totalRuleCount = this.state.ruleset ? this.state.ruleset.rules.length + ruleCount : ruleCount;

    if (totalRuleCount > this.getMaxRulesetRules()) {
      actionCreators.openDialog(
        <AlertDialog
          title={intl('PolicyGenerator.TooManyRules')}
          message={intl('PolicyGenerator.TooManyRulesMessage')}
        />,
      );

      return;
    }

    if (this.state.ruleset) {
      if (!this.verifyRuleset(this.state.ruleset)) {
        return;
      }
    }

    const removeRules = this.state.removeRules;

    let message = (
      <div>
        {removeRules && removeRules.length ? (
          <div className="PolicyGenerator-Save">
            {intl('PolicyGenerator.RulesWillBeDeleted', {count: removeRules.length})}
          </div>
        ) : null}
        <div className="PolicyGenerator-Save">{intl('PolicyGenerator.RulesWillBeSaved', {count: ruleCount})}</div>
        <div className="PolicyGenerator-Save">
          {intl('PolicyGenerator.ServiceCreationWarning', {count: this.state.services.length})}
        </div>
        <div className="PolicyGenerator-Save">
          {intl('PolicyGenerator.IpListCreationWarning', {count: this.state.ipLists.length})}
        </div>
      </div>
    );

    if (
      (this.state.services && this.state.services.length > this.getObjectsPerMin()) ||
      ruleCount > this.getObjectsPerMin()
    ) {
      message = (
        <div>
          {removeRules && removeRules.length ? (
            <div className="PolicyGenerator-Save">
              {intl('PolicyGenerator.RulesWillBeDeleted', {count: removeRules.length})}
            </div>
          ) : null}
          <div className="PolicyGenerator-Save">{intl('PolicyGenerator.RulesWillBeSaved', {count: ruleCount})}</div>
          <div className="PolicyGenerator-Save">
            {intl('PolicyGenerator.ServiceCreationWarning', {count: this.state.services.length})}
          </div>
          <div className="PolicyGenerator-Save">
            {intl('PolicyGenerator.IpListCreationWarning', {count: this.state.ipLists.length})}
          </div>
          <div className="PolicyGenerator-Warning">
            {intl('PolicyGenerator.ServiceWaitTime', {
              count:
                Math.round(this.state.services.length / this.getObjectsPerMin()) +
                Math.round(ruleCount / this.getObjectsPerMin()),
            })}
          </div>
        </div>
      );
    }

    actionCreators.openDialog(
      <ConfirmationDialog
        title={intl('PolicyGenerator.SaveRules')}
        message={message}
        onConfirm={this.handleForceSave}
      />,
    );
  },

  async handleForceSave() {
    const rules = this.state.rules;
    const services = PolicyGeneratorUtils.getServices({}, this.state.rules);
    const ipLists = PolicyGeneratorUtils.getIpLists(this.state.rules);
    let returnValue;
    let rulesetId;

    if (services.length) {
      returnValue = await this.createServices(services, rules);
    }

    if (returnValue !== 'error' && ipLists.length) {
      returnValue = await this.createIpLists(ipLists, rules);
    }

    const removeRules = this.state.removeRules;

    if (returnValue !== 'error') {
      rulesetId = await this.createRules(rules, removeRules);

      if (rulesetId !== 'error') {
        this.setIpListSuccessInfo({
          appGroupName: this.state.appGroup.name,
          appGroupHref: this.state.appGroup.href,
          connectedAppGroup: this.getPolicyGeneratorValues().selectedConnectedAppGroups[0],
          newRules: rules.length,
          newServices: services.length,
          rulesetId,
        });

        const id = this.getParams() && this.getParams().id;

        if (id) {
          this.transitionTo('appGroupIpListSuccess', {id});
        } else {
          this.transitionTo('ipListSuccess');
        }
      }
    }

    if (returnValue === 'error' || rulesetId === 'error') {
      this.setState({loadingSpinner: false, spinner: false});
    }
  },

  handleBack() {
    const id = this.getParams() && this.getParams().id;

    if (id) {
      this.transitionTo('appGroupIpListConfigure', {id});
    } else {
      this.transitionTo('ipListConfigure');
    }
  },

  handleServiceNameChange(oldRow, index, newValue, useAlways) {
    if (!newValue) {
      return;
    }

    let rules = [...this.state.rules];
    const row = rules.find(rule => rule.key === oldRow.key);

    row.services[index] = newValue[0];

    rules = rules.map(rule => {
      // Replace any service in the edited rule regardless of whether the user chose 'useAlways'
      // This is because once a service covers another port in the same rule, we don't want duplicates
      if (useAlways || rule === row) {
        rule.services &&= rule.services.map((service, index) => {
          const key = PolicyGeneratorUtils.getServicePortKey(service);

          // If this rule contains is not using a previously chosen service and
          // the new service in its moreServicees list, use it
          if (
            !this.useAlwaysServices.includes(service.href) &&
            rule.port &&
            rule.port[key] &&
            ServiceUtils.matchConnectionWithService(rule.port[key][0], newValue).length
          ) {
            if (!oldRow.oldServiceName) {
              rule.oldServiceNames ||= Array.from({length: rule.services.length});

              rule.oldServiceNames[index] = service.name;
            }

            return newValue[0];
          }

          return service;
        });
      }

      return rule;
    });

    if (useAlways && newValue.href) {
      this.useAlwaysServices.push(newValue.href);
    }

    const mergedRules = PolicyGeneratorUtils.getMergedRules([...rules], this.state.mergeType);

    if (mergedRules.length === rules.length) {
      this.setState({
        rules: mergedRules,
        services: PolicyGeneratorUtils.getServices({}, mergedRules),
      });
    } else {
      actionCreators.openDialog(
        <ConfirmationDialog
          title={intl('Rule.Merge')}
          message={intl('PolicyGenerator.ConfirmMergeRules')}
          onConfirm={_.partial(this.mergeRules, mergedRules)}
        />,
      );

      this.setState({
        rules,
        services: PolicyGeneratorUtils.getServices({}, rules),
      });
    }
  },

  mergeRules(mergedRules) {
    this.setState({
      rules: mergedRules,
      services: PolicyGeneratorUtils.getServices({}, mergedRules),
    });
  },

  handleServiceEdit(row, index, value) {
    if (!row.newService[index]) {
      actionCreators.openDialog(
        <ConfirmationDialog
          title={intl('PolicyGenerator.ResetRules')}
          message={intl('PolicyGenerator.ResetRulesReason')}
          onConfirm={_.partial(this.handleResetRules, this.state.mergeType, this.state.serviceType)}
        />,
      );

      return;
    }

    this.setState({edit: value ? [row.key, index].join(',') : false});
  },

  getRuleNote(value, row) {
    const {showNote, showNoteTop} = this.state;

    return row.description ? (
      <RuleNote
        isReadOnly
        href={row.key}
        onNoteIconClick={this.handleNoteIconClick}
        onNoteCloseClick={this.handleNoteCloseClick}
        value={value}
        showNote={row.key === showNote}
        showNoteTop={showNoteTop}
        onSetNoteBox={this.handleSetNoteBox}
      />
    ) : null;
  },

  handleCompClick(evt) {
    const isNoteIcon = evt.target.classList.contains('Icon-RulesetRules-NoteIcon');

    if (!this.state.showNote || !this.noteBox || isNoteIcon) {
      return;
    }

    if (!this.noteBox.contains(evt.target)) {
      this.handleNoteCloseClick();
    }
  },

  handleNoteCloseClick() {
    this.setState({showNote: null});
  },

  handleNoteIconClick(key, showNoteTop) {
    if (this.state.showNote === key) {
      this.setState({showNote: null});

      return;
    }

    this.setState({showNote: key, showNoteTop});
  },

  handleSetNoteBox(node) {
    this.noteBox = node;
  },

  getExpansion(value, row) {
    if (value) {
      return null;
    }

    if (row.role && this.state.expandedRoles.includes(row.role.href)) {
      return <Icon name="caret-down" size="large" />;
    }

    return <Icon name="caret-right" size="large" />;
  },

  handleRowClick(row) {
    const expandedRoles = [...this.state.expandedRoles];

    if (row.role && expandedRoles.includes(row.role.href)) {
      expandedRoles.splice(expandedRoles.indexOf(row.role.href), 1);
    } else if (row.role) {
      expandedRoles.push(row.role.href);
    }

    const roles = this.getAppGroupRoles(TrafficStore.getAllRoleNodes(), this.state.appGroup.href).map(
      role => role.href,
    );
    const roleVulnerabilities = PolicyGeneratorUtils.getRoleVulnerabilities(roles, expandedRoles);

    this.setState({expandedRoles, roleVulnerabilities});
  },

  handleMergeSelect(evt) {
    this.handleResetRules(evt.target.value, this.state.serviceType, this.state.broadServiceMatch);
    this.setState({mergeType: evt.target.value});
  },

  handleServiceTypeSelect(evt) {
    this.handleResetRules(this.state.mergeType, evt.target.value, this.state.broadServiceMatch);
    this.setState({serviceType: evt.target.value});
  },

  handleBroadServiceSelect() {
    this.handleResetRules(this.state.mergeType, this.state.serviceType, !this.state.broadServiceMatch);
    this.setState({broadServiceMatch: !this.state.broadServiceMatch});
  },

  openPortProtocolDialog(services) {
    actionCreators.openDialog(<PolicyGeneratorPortProtocolDialog services={services} />);
  },

  render() {
    const {
      appGroup,
      ruleset,
      rulesetName,
      rules,
      removeRules,
      numRules,
      ipListTableTraffic,
      ipListExclusions,
      ipListConfig,
      counts,
      spinner,
      loadingSpinner,
      modal,
      roleVulnerabilities,
      services,
      ipLists,
      rulesetDisabled,
      providerConsumerOrder,
      vesError,
    } = this.state;

    if (!appGroup) {
      return null;
    }

    const labels = [appGroup.labels.map(label => ({label: {...label}}))]; // Get labels in the format to be used in the grid
    const scopes = RulesetUtils.getScopesWithLabelKeys(labels);
    const newRulesCount = rules ? rules.length : 0;
    const newServicesCount = services ? services.length : 0;
    const newIpListCount = ipLists ? ipLists.length : 0;
    const writable = this.state.appGroup.caps.rulesets.includes('write');

    let addRules = [...(rules || [])];
    let scopesCount = 0;
    let rulesCount = 0;
    let servicesCount = 0;
    let total = 0;
    let existing = 0;
    let ruleCoverage;
    let percent;
    let rulesetDisabledNotification;

    if (rulesetDisabled) {
      rulesetDisabledNotification = (
        <div className="AppGroupCoverage-notification">
          <Notification
            type="error"
            message={intl('ApplicationsCoverage.RulesetDisabled', {disabled: rulesetDisabled})}
          />
        </div>
      );
    }

    if (loadingSpinner && !modal) {
      let message;

      switch (loadingSpinner) {
        case 'rules':
          message = intl('PolicyGenerator.Spinner.CalculatingFlowData');
          break;
        case 'vulnerabilities':
          message = intl('PolicyGenerator.Spinner.CalculatingVulnerabilityData');
          break;
        default:
          message = intl('PolicyGenerator.Spinner.FindingConnections');
      }

      return (
        <div className="RBConfigure RBConfigure--Extra--Choose RBConfigure--Extra">
          <Banner type="progresscentered" header={intl('PolicyGenerator.CalculationInProgress')} message={message} />
        </div>
      );
    }

    if (ruleset) {
      scopesCount = ruleset.scopes.length;
      rulesCount = ruleset.rules.length;
      // Length of services is the length of unique hrefs of services in every rule
      servicesCount = Object.keys(
        ruleset.rules.reduce((result, rule) => {
          if (rule.ingress_services) {
            rule.ingress_services.forEach(service => {
              // Find all the real services
              if (service.href) {
                result[service.href] = null;
              }
            });
          }

          return result;
        }, {}),
      ).length;
    }

    if (ipListTableTraffic) {
      total = counts.ipListConnections;
      existing = counts.ipListConnectionRules;
      ruleCoverage = PolicyGeneratorUtils.calculateRuleCoverage(
        ipListTableTraffic,
        counts.ipListConnectionsWorkingTotal,
        ipListExclusions,
      );
      percent = total ? (ruleCoverage + existing) / total : 0;
    }

    const ruleColumns = [
      ...ProviderConsumerUtils.setProviderConsumerColumnOrderArrow(
        {
          key: 'providers',
          label: intl('Common.Destinations'),
          style: 'providers',
          format: (value, row) => GridDataUtils.formatRuleEntities(value, row),
        },
        {
          key: 'services',
          label: __ANTMAN__ ? intl('Common.Service') : intl('Rulesets.Rules.ProvidingService'),
          style: 'service',
          format: (value, row) => {
            const services = (value || row.ingress_services || []).map((service, index) => {
              let ports;

              if (service.service_ports || service.windows_services) {
                ports = (service.service_ports || service.windows_services).map(connection => (
                  <div>{PortUtils.stringifyPortObjectReadonly(connection, true)}</div>
                ));

                if (ports.length > 5) {
                  ports = (
                    <div>
                      {ports.slice(0, 5)}
                      <div
                        className="PolicyGeneratorGrid-port-protocol-link"
                        onClick={_.partial(
                          this.openPortProtocolDialog,
                          service.service_ports || service.windows_services,
                        )}
                      >
                        {intl('Common.More')}
                      </div>
                    </div>
                  );
                }
              }

              return (
                <div className={service.updated ? 'text-updated' : null}>
                  <SelectService
                    row={row}
                    index={index}
                    name={
                      service.name ||
                      `${ServiceUtils.getPort(service) || ''} ${ServiceUtils.lookupProtocol(service.proto)}`
                    }
                    policyGenerator={true}
                    onClose={_.partial(this.handleServiceNameChange, row, index)}
                    edit={this.state.edit === [row.key, index].join(',')}
                    editable={
                      !this.state.edit &&
                      !row.href &&
                      !(service.name.includes('ICMP') || ServiceUtils.isIcmp(service.proto))
                    }
                    onEdit={_.partial(this.handleServiceEdit, row, index)}
                  />
                  {this.state.edit === [row.key, index].join(',') ? null : (
                    <div className="PolicyGenerator-Service-connections">{ports}</div>
                  )}
                </div>
              );
            });

            return (
              <div className="PolicyGenerator-multi-services">
                {services.length ? services : intl('Rulesets.Rules.DerivedFromProviderVirtualServices')}
              </div>
            );
          },
        },
        {
          key: 'consumer_to_provider_arrow',
          style: 'consumerToProviderArrow',
        },
        {
          key: 'consumers',
          label: intl('Common.ConsumersGlobal'),
          style: 'consumers',
          format: (value, row) => GridDataUtils.formatRuleEntities(value, row),
        },
        providerConsumerOrder,
      ),
    ];

    if (ipListConfig.type === 'auto') {
      ruleColumns.push({
        key: 'description',
        sortable: false,
        label: intl('Common.Note'),
        style: 'RulesetRules-Note',
        format: (value, row) => this.getRuleNote(value, row),
      });
    }

    const scopeColumns = [
      {
        key: 'app',
        label: intl('Common.Application'),
        style: 'scope',
        format: label => RulesetUtils.formatScopeLabel('app', label),
      },
      {
        key: 'env',
        label: intl('Common.Environment'),
        sortable: true,
        style: 'scope',
        format: label => RulesetUtils.formatScopeLabel('env', label),
      },
      {
        key: 'loc',
        label: intl('Common.Location'),
        sortable: true,
        style: 'scope',
        format: label => RulesetUtils.formatScopeLabel('loc', label),
      },
    ];

    const vulnerabilityColumns = [
      {
        key: 'protocol',
        style: 'icon',
        format: (value, row) => this.getExpansion(value, row),
      },
      {
        key: 'role',
        label: intl('Common.Role'),
        style: 'vulnerability-whatif',
        format: value => (value ? <Label type="role" text={value.name} /> : null),
      },
      {
        key: 'port',
        label: intl('Common.Vulnerability'),
        style: 'vulnerability-whatif',
        sortable: true,
        sortValue: value => value,
        format: (value, row) => {
          if (row.port) {
            return GridDataUtils.formatPortProtocol(row);
          }

          return intl('Vulnerability.Vulnerabilities', {count: row.numVulnerabilities});
        },
      },
      {
        key: 'wideExposure',
        label: intl('Vulnerability.Before'),
        style: 'internet',
        sortable: true,
        format: value =>
          Object.values(value).some(value => value) ? <Icon name="internet" size="large" /> : intl('Common.None'),
      },
      {
        key: 'projected',
        style: 'vulnerability-projected-ves',
        label: intl('Vulnerability.After'),
        format: value => {
          if (!value || !value.wideExposure) {
            return SessionStore.isSuperclusterLeader() || SessionStore.isSuperclusterMember() || vesError
              ? intl('Common.NA')
              : intl('Vulnerability.Calculating');
          }

          return Object.values(value.wideExposure).some(value => value) ? (
            <Icon name="internet" size="large" />
          ) : (
            intl('Common.None')
          );
        },
        sortable: true,
      },
    ];

    let moreRules = 0;
    const maxRules = this.getMaxTableRules();

    if (addRules.length > maxRules) {
      moreRules = rules.length - maxRules;
      addRules = addRules.splice(0, maxRules);
    }

    let noRules;

    if (!addRules.length && numRules) {
      noRules = <Banner type="notice" header="All Rules already exist" />;
    }

    const existingRules = scopesCount || rulesCount || servicesCount;

    const mergeRadioGroupData = [
      {key: 'services', value: intl('PolicyGenerator.MergeServices')},
      {key: 'consumers', value: intl('PolicyGenerator.MergeConsumers')},
    ];

    const serviceRadioGroupData = [
      {key: 'port', value: intl('PolicyGenerator.PortServices')},
      {key: 'new', value: intl('Explorer.CreateNewService')},
    ];

    return (
      <div className="RBPreview RBPreview--Extra">
        {spinner && !modal ? (
          <Banner
            type="progresscentered"
            header={intl('PolicyGenerator.CalculationInProgress')}
            message={intl('PolicyGenerator.Spinner.GeneratingRules', {percent: this.state.progress || 0})}
          />
        ) : null}
        <div className="RBPreview-Content">
          <ProgressBar
            steps={[
              intl('PolicyGenerator.SelectApplications'),
              intl('PolicyGenerator.IpList.ChooseIpList'),
              intl('PolicyGenerator.IpList.ConfigureIpList'),
              intl('PolicyGenerator.PreviewRules'),
            ]}
            active={3}
          />
          <div className="RBConfigure-Content-Nav">
            <Button text={intl('Common.Back')} type="secondary" onClick={this.handleBack} tid="back" />
          </div>
          <p className="RBPreview-Content-PreviewTable-Title">
            <strong>{intl('Common.Application')}:</strong> {appGroup.name}
          </p>
          {rulesetDisabledNotification}
          <div className="RBPreview-Content-ScopesRules">
            <div className="RBPreview-Content-ScopesRules-GridContainer">
              <h2 className="RBPreview-Content-ScopesRules-GridContainer-Title">
                {intl('PolicyGenerator.RulesetScope')}
              </h2>
              <Grid columns={scopeColumns} data={scopes} selectable={true} />
            </div>
            {removeRules && removeRules.length ? (
              <div className="RBPreview-Content-ScopesRules-GridContainer RBPreview-Content-ScopesRules-GridContainer--Rules">
                <h2 className="RBPreview-Content-ScopesRules-GridContainer-Title">
                  {intl('PolicyGenerator.IntraScope.DeleteIntraScopeRules', {count: removeRules.length})}
                </h2>
                <Grid columns={ruleColumns} data={removeRules} selectable={true} />
              </div>
            ) : null}
            {ipListConfig.type === 'microsegmentation' || ipListConfig.type === 'auto' ? (
              <div className="RBPreview-Construction">
                <p className="RBPreview-Content-PreviewTable-Title">
                  <strong>{intl('PolicyGenerator.RuleConstruction')}:</strong>
                </p>
                <div className="RBPreview-Construction-Group">
                  <div className="RBPreview-Construction-Title">{intl('PolicyGenerator.RuleMerging')}</div>
                  <RadioGroup
                    data={mergeRadioGroupData}
                    value={this.state.mergeType}
                    format="vertical"
                    onChange={this.handleMergeSelect}
                  />
                </div>
                <div className="RBPreview-Construction-Group">
                  <div className="RBPreview-Construction-Title">
                    {__ANTMAN__ ? intl('Common.Service') : intl('Rulesets.Rules.ProvidingService')}
                  </div>
                  <div className="RBPreview-Construction-Description">{intl('PolicyGenerator.ServiceUse')}</div>
                </div>
                <div className="RBPreview-Construction-Service-Group">
                  <div className="RBPreview-Checkbox">
                    <Checkbox
                      label={
                        <span className="RBPreview-BroadRules">
                          <div>{intl('PolicyGenerator.UseBroadServices')}</div>
                          <div className="RBPreview-BroadRulesWarning">
                            {intl('PolicyGenerator.BroadServiceWarning')}
                          </div>
                        </span>
                      }
                      value="broadServiceMatch"
                      checked={this.state.broadServiceMatch}
                      onChange={this.handleBroadServiceSelect}
                      tid="broad-service"
                    />
                  </div>
                  {this.state.scopedUser ? null : (
                    <div className="RBPreview-Construction-SubGroup">
                      <div className="RBPreview-Construction-Description">
                        {intl('PolicyGenerator.ServiceSubstitution')}
                      </div>
                      <RadioGroup
                        data={serviceRadioGroupData}
                        value={this.state.serviceType}
                        format="vertical"
                        onChange={this.handleServiceTypeSelect}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            <div className="RBPreview-Content-ScopesRules-GridContainer RBPreview-Content-ScopesRules-GridContainer--Rules">
              <h2 className="RBPreview-Content-ScopesRules-GridContainer-Title">
                {intl('PolicyGenerator.IntraScope.NewIntraScopeRules', {count: addRules.length})}
              </h2>
              <Grid columns={ruleColumns} data={addRules} selectable={true} emptyContent={noRules} />
              {moreRules > 0 && (
                <div className="PolicyGeneratorGrid-more">
                  {intl('PolicyGenerator.Grid.MoreRules', {count: moreRules})}
                </div>
              )}
            </div>
            {roleVulnerabilities && roleVulnerabilities.length ? (
              <div className="RBPreview-Content-ScopesRules-GridContainer RBPreview-Content-ScopesRules-GridContainer--Rules">
                <h2 className="RBPreview-Content-ScopesRules-GridContainer-Title">
                  {intl('Vulnerability.MitigatedVulnerabilities', {
                    count: roleVulnerabilities.reduce(
                      (result, role) => (result += role.role ? role.numVulnerabilities : 0),
                      0,
                    ),
                  })}
                </h2>
                <Grid columns={vulnerabilityColumns} data={roleVulnerabilities} onRowClick={this.handleRowClick} />
              </div>
            ) : null}
          </div>
          <div className="RBPreview-Content-Confirm">
            <h3 className="RBPreview-Content-Confirm-Title">
              {existingRules
                ? `${intl('ApplicationsCoverage.AddToRuleset')} ${rulesetName || ruleset.name}`
                : `${intl('ApplicationsCoverage.BuildNewRuleset')} ${rulesetName || appGroup.name}`}
            </h3>
            <div className="RBPreview-Content-Confirm-Percent">
              {intl('ApplicationsCoverage.NewIpListRuleCoverageStrong', {val: percent}, {html: true})}
            </div>
            <div className="RBPreview-Content-Confirm-Heading">{intl('ApplicationsCoverage.NewObjectInRuleset')}</div>
            <div className="RBPreview-Content-Confirm-Info">
              {newRulesCount > 0 && intl('PolicyGenerator.NewRules', {count: newRulesCount}, {html: true})}
              <br />
              {newServicesCount > 0 && intl('PolicyGenerator.NewServices', {count: newServicesCount}, {html: true})}
              <br />
              {newIpListCount > 0 && intl('PolicyGenerator.NewIpLists', {count: newIpListCount}, {html: true})}
            </div>
            {removeRules && removeRules.length ? (
              <div>
                <div className="RBPreview-Content-Confirm-Heading">
                  {intl('ApplicationsCoverage.DeletingRulesInRuleset')}
                </div>
                <div className="RBPreview-Content-Confirm-Info">
                  {rulesCount > 0 && intl('PolicyGenerator.Rules', {count: removeRules.length}, {html: true})}
                  <br />
                </div>
              </div>
            ) : null}
            {!existingRules || (removeRules && removeRules.length) ? (
              <div className="RBPreview-Content-Confirm-Buttons">
                <Button text={intl('Common.Cancel')} icon="cancel" type="secondary" onClick={this.handleCancel} />
                <Button text={intl('Common.Save')} icon="save" onClick={this.handleSave} disabled={!writable} />
              </div>
            ) : null}
          </div>
          {existingRules > 0 && (!removeRules || !removeRules.length) && (
            <div className="RBPreview-Content-Confirm-Existing">
              <div className="RBPreview-Content-Confirm-Heading">
                {intl('ApplicationsCoverage.ExistObjectInRuleset')}
              </div>
              <div className="RBPreview-Content-Confirm-Info">
                {scopesCount > 0 && intl('PolicyGenerator.Scopes', {count: scopesCount}, {html: true})}
                <br />
                {rulesCount > 0 && intl('PolicyGenerator.Rules', {count: rulesCount}, {html: true})}
                <br />
                {servicesCount > 0 && intl('PolicyGenerator.Services', {count: servicesCount}, {html: true})}
                <br />
                {newIpListCount > 0 && intl('PolicyGenerator.NewIpLists', {count: newIpListCount}, {html: true})}
              </div>
              <div className="RBPreview-Content-Confirm-Buttons">
                <Button text={intl('Common.Cancel')} icon="cancel" type="secondary" onClick={this.handleCancel} />
                <Button
                  text={intl('Common.Save')}
                  icon="save"
                  disabled={!addRules.length || rulesetDisabled || !writable}
                  onClick={this.handleSave}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
});
